import React, { useState, useEffect } from "react";
import "./NeedPermissionsScreen.css";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import MicIcon from "@mui/icons-material/Mic";
import GppGoodIcon from "@mui/icons-material/GppGood";
import { Button, Divider } from "@mui/material";
import NeedAssessComponent from "./Components/NeedAssessComponent/NeedAssessComponent";
import GrantPermissionsStepsDialog from "../../components/GrantPermissionsStepsDialog/GrantPermissionsStepsDialog";
import useTranslationSetup from "../../utils/useTranslationSetup";
import PermissionsStatusLoader from "./Components/PermissionsStatusLoader/PermissionsStatusLoader";
import CloudOrange from "../../assets/cloud-orange.svg";
import CloudBlue from "../../assets/cloud-blue.svg";
import CloudPink from "../../assets/cloud-pink.svg";
import useParamValues from "../../utils/useParamValues";

function NeedPermissionsScreen({
  setShowNeedPermissionsScreen,
  setLatitude,
  setLongitude,
  setShowVopaInstructionScreen,
  setShowVopaInputFieldsScreen,
}) {
  const { t } = useTranslationSetup();
  const [audioPermissions, setAudioPermissions] = useState(null);
  const [locationPermissions, setLocationPermissions] = useState(null);
  const [
    openPermissionsInformationDialog,
    setOpenPermissionsInformationDialog,
  ] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const { organisation } = useParamValues();

  useEffect(() => {
    if (audioPermissions !== null && locationPermissions !== null) {
      setShowLoader(false);
    }
    if (
      audioPermissions !== null &&
      locationPermissions !== null &&
      (audioPermissions === "DENIED" || locationPermissions === "DENIED")
    ) {
      setOpenPermissionsInformationDialog(true);
    }
    if (audioPermissions === "GRANTED" && locationPermissions === "GRANTED") {
      setShowNeedPermissionsScreen(false);
      // setShowVopaAboutScreen(true);
      if (organisation === "bcg_raj_2") {
        setShowVopaInputFieldsScreen(true);
      } else {
        setShowVopaInstructionScreen(true);
      }
    }
  }, [audioPermissions, locationPermissions]);

  function askAudioPermissions() {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        setAudioPermissions("GRANTED");
        stream.getTracks().forEach((track) => track.stop());
      })
      .catch(() => {
        setAudioPermissions("DENIED");
      });
  }

  function askGeolocationPermissions() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          setLocationPermissions("GRANTED");
        },
        () => {
          setLocationPermissions("DENIED");
        }
      );
    } else {
      setLocationPermissions("GEOLOCATION_NOT_SUPPORTED");
    }
  }

  function askPermissions() {
    setShowLoader(true);
    askGeolocationPermissions();
    askAudioPermissions();
  }

  return (
    <div className="need-permissions-container">
      <PermissionsStatusLoader open={showLoader} />
      <GrantPermissionsStepsDialog
        isDialogOpen={openPermissionsInformationDialog}
        setIsDialogOpen={setOpenPermissionsInformationDialog}
      />
      <div className="need-permissions-cloud">
        <img className="blue-cloud" src={CloudBlue} alt="clouds" />
        <img className="pink-cloud" src={CloudPink} alt="clouds" />
      </div>
      <div className="need-permissions-screen-heading">
        {t("need_permissions")}
      </div>
      <div className="need-permissions-screen-desc">
        {t("need_permissions_description")}
      </div>
      <div className="need-to-assess-text">{t("we_need_to_access")}</div>
      <div className="need-assess-container">
        <NeedAssessComponent
          icon={<FmdGoodIcon />}
          heading={t("location_access")}
          content={t("location_access_description")}
        />
        <Divider
          style={{
            margin: "18px 10%",
          }}
        />
        <NeedAssessComponent
          icon={<MicIcon />}
          heading={t("microphone_access")}
          content={t("microphone_access_description")}
        />
      </div>
      <div className="need-assess-container">
        <NeedAssessComponent
          icon={<GppGoodIcon />}
          heading={t("data_security")}
          content={t("data_security_description")}
        />
      </div>
      <div
        style={{
          textAlign: "center",
        }}
        className="sticky-approve-button"
      >
        <Button
          variant="contained"
          disableElevation
          onClick={() => {
            askPermissions();
          }}
          sx={{ background: "#E47714" }}
        >
          {t("i_approve")}
        </Button>
      </div>
      <div className="cloudsBottomOrange">
        <img src={CloudOrange} alt="orange-cloud-img" />
      </div>
    </div>
  );
}

export default NeedPermissionsScreen;
