import React, { useState, useEffect } from "react";
import RestoreOutlinedIcon from "@mui/icons-material/RestoreOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import Lottie from "react-lottie-player";
import CustomErrorDialogBox from "./components/CustomErrorDialogBox/CustomErrorDialogBox";
import useTranslationSetup from "./utils/useTranslationSetup";
import Loading from "./assets/LottieFiles/resultLoading.json";
import "./ResultLoadingScreen.css";
import { UrlConstants } from "./utils/CommonUtils/constants";
import fetchStatus from "./fetchApi/fetchStatus";

function ResultLoadingScreen({
  setShowResultLoadingScreen,
  setShowHomeScreen,
  setAudioBlobForAudioPlayer,
  setHideSlider,
  orfResultStatus,
  setOrfResultStatus,
  setSaveClicked,
  setAudioUploading,
  setUploadAudioStatus,
  setShowOrfTestScreen,
  setShowVopaInputFieldsScreen,
  setName,
  setClassNum,
  setProfession,
  setSchoolUdise,
  setInstructorName,
  setPhoneNumber,
  setGender,
  setAge,
  setReadingLevel,
  setShowAssessmentLangScreen,
  setAssessmentLang,
  setShowAssessmentListScreen,
  cardClicked,
  assessmentId,
  setShowOrfResultScreen,
  setProcessedData,
  organisation,
  mode,
  setCardClicked,
  setDataCollectorName,
  setDataCollectorPhoneNumber,
}) {
  const { t } = useTranslationSetup();
  const [openDialogForError, setOpenDialogForError] = useState(false);

  useEffect(() => {
    if (cardClicked) {
      fetchStatus(
        `${
          UrlConstants.BASE_URL +
          UrlConstants.BASE_PATH_URL +
          UrlConstants.ASSESSMENT
        }/${assessmentId}?get_audio=true`
      )
        .then((data) => {
          if (data.status === "COMPLETED") {
            setShowResultLoadingScreen(false);
            setShowOrfResultScreen(true);
            setOrfResultStatus(data.status);
            setProcessedData(
              organisation === "vopa" ? data.data.result : data.data
            );
          } else {
            setOrfResultStatus(data.status);
            setOpenDialogForError(true);
          }
        })
        .catch((error) => {
          setOrfResultStatus(error.status);
          setOpenDialogForError(true);
        });
    }
  }, [cardClicked]);

  useEffect(() => {
    if (
      orfResultStatus === "UNDETERMINED" ||
      orfResultStatus === "FAILED" ||
      orfResultStatus === "ERROR" ||
      orfResultStatus === "ERR_INTERNET_DISCONNECTED"
    ) {
      setOpenDialogForError(true);
    }
  }, [orfResultStatus]);

  const onClickPrimaryButton = () => {
    setOpenDialogForError(false);
    if (orfResultStatus === "FAILED") {
      setShowResultLoadingScreen(false);
      setShowOrfTestScreen(true);
    } else {
      setShowResultLoadingScreen(false);
      setAssessmentLang(null);
      setShowAssessmentLangScreen(true);
    }
    if (orfResultStatus === "FAILED") {
      setShowResultLoadingScreen(false);
      setShowOrfTestScreen(true);
    } else {
      setShowResultLoadingScreen(false);
      setAssessmentLang(null);
      setShowAssessmentLangScreen(true);
    }
    setAudioBlobForAudioPlayer(null);
    setHideSlider(false);
    setOrfResultStatus(null);
    setUploadAudioStatus(null);
    setAudioUploading(false);
    setSaveClicked(false);
  };
  const onClickSecondaryButton = () => {
    setOpenDialogForError(false);
    setAudioBlobForAudioPlayer(null);
    setShowResultLoadingScreen(false);
    setAssessmentLang(null);
    setShowHomeScreen(true);
    if (mode === "assessment") {
      setShowVopaInputFieldsScreen(true);
      setName("");
      setClassNum("");
      setProfession("");
      setSchoolUdise("");
      setInstructorName("");
      setPhoneNumber("");
      setGender("");
      setAge("");
      setReadingLevel("");
      setDataCollectorName("");
      setDataCollectorPhoneNumber("");
    } else if (mode === "performance") {
      setShowVopaInputFieldsScreen(false);
      setShowAssessmentListScreen(true);
      setCardClicked(false);
    }
    setAssessmentLang(null);
    setHideSlider(false);
    setOrfResultStatus(null);
    setUploadAudioStatus(null);
    setAudioUploading(false);
    setSaveClicked(false);
  };

  return (
    <>
      {orfResultStatus === "UNDETERMINED" && (
        <CustomErrorDialogBox
          header={t("taking_longer_than_usual")}
          subHeader={t("assessment_taking_longer_than_usual")}
          icon={
            <RestoreOutlinedIcon
              style={{
                color: "rgba(228, 119, 20, 1)",
                borderRadius: "10px",
                marginTop: "16px",
                width: "70px",
                height: "70px",
              }}
            />
          }
          primaryButtonText={t("new_language")}
          secondaryButtonText={t("new_student")}
          isDialogOpen={openDialogForError}
          showPrimaryButton
          showSecondaryButton
          onClickPrimaryButton={onClickPrimaryButton}
          onClickSecondaryButton={onClickSecondaryButton}
        />
      )}
      {(orfResultStatus === "FAILED" ||
        orfResultStatus === "ERROR" ||
        orfResultStatus === "ERR_INTERNET_DISCONNECTED") && (
        <CustomErrorDialogBox
          header={t("something_went_wrong")}
          icon={
            <ErrorOutlineOutlinedIcon
              style={{
                color: "#D95959",
                borderRadius: "10px",
                marginTop: "16px",
                width: "80px",
                height: "80px",
              }}
            />
          }
          primaryButtonText={orfResultStatus === "FAILED" ? t("retake") : null}
          secondaryButtonText={mode==="assessment"?t("new_student"):t("back")}
          isDialogOpen={openDialogForError}
          showPrimaryButton={orfResultStatus === "FAILED"}
          showSecondaryButton
          onClickPrimaryButton={onClickPrimaryButton}
          onClickSecondaryButton={onClickSecondaryButton}
        />
      )}
      <div className="loader-container">
        <div className="loader-content">
          <Lottie loop animationData={Loading} play />
          <h1 className="loader-heading">{t("processing_result")}</h1>
          <p className="loader-text">{t("your_audio_saved_please_wait")}</p>
        </div>
        <div className="loader-bottom-text-wrapper">
          <div className="loader-bottom-text">
            {t("thank_you_for_patience_fine_tuning")}
          </div>
        </div>
      </div>
    </>
  );
}

export default ResultLoadingScreen;
