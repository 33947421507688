export const QueryParams = Object.freeze({
  ORGNISATION: "organisation",
  APP_LANGUAGE: "app_language",
});

export const OrganisationMapping = Object.freeze({
  demo: "generic_demo",
  pratham_demo_1: "pratham_demo_1",
  bihar_demo_1: "bihar_pilot_1",
  bihar_pilot_1: "bihar_pilot_1",
  bihar_pilot_2: "bihar_pilot_2",
  delhi_mcd_1: "delhi_mcd_1",
  bcg_rajasthan_1: "bcg_rajasthan_1",
  isaksham_pilot_2: "isaksham_pilot_2",
  bcg_raj_2: "bcg_raj_2",
});

export const UrlConstants = Object.freeze({
  BASE_URL: process.env.REACT_APP_BASE_URL,
  BASE_PATH_URL: process.env.REACT_APP_BASE_PATH_URL,
  ASSESSMENT: process.env.REACT_APP_ASSESSMENT,
  TOKEN: process.env.REACT_APP_TOKEN,
  DSN_SENTRY_URL: process.env.REACT_APP_DSN_SENTRY_URL,
});

export const SchoolIdName = [
  {
    schoolId: "1556029",
    schoolName: "HARI NAGAR L BLOCK",
  },
  {
    schoolId: "1556030",
    schoolName: "GURU NANAK PURA",
  },
  {
    schoolId: "1556031",
    schoolName: "PARTAP NAGAR",
  },
  {
    schoolId: "1556039",
    schoolName: "TIHAR NO 2",
  },
  {
    schoolId: "1152530",
    schoolName: "KARDAMPURI-I",
  },
  {
    schoolId: "1152531",
    schoolName: "KARDAMPURI-II",
  },
  {
    schoolId: "1152074",
    schoolName: "ASHOK NAGAR",
  },
  {
    schoolId: "1152075",
    schoolName: "ASHOK NAGAR",
  },
  {
    schoolId: "1958016",
    schoolName: "ANDREWJ GANJ",
  },
  {
    schoolId: "1958006",
    schoolName: "BAPU PARK",
  },
  {
    schoolId: "1958010",
    schoolName: "SOUTH EXTN PART I",
  },
  {
    schoolId: "1958024",
    schoolName: "LAJPAT NAGAR PART II",
  },
  {
    schoolId: "2161423",
    schoolName: "BAGH KARE KHAN",
  },
  {
    schoolId: "2161047",
    schoolName: "PRATAP NAGAR",
  },
  {
    schoolId: "1152095",
    schoolName: "JYOTI COLONY",
  },
  {
    schoolId: "2161055",
    schoolName: "BARA HINDU RAO",
  },
  {
    schoolId: "1958003",
    schoolName: "SEWA NAGAR N BLOCK",
  },
  {
    schoolId: "1556061",
    schoolName: "TILAK NAGAR NO 2",
  },
  {
    schoolId: "1556058",
    schoolName: "TILAK NAGAR NO 3",
  },
  {
    schoolId: "2160316",
    schoolName: "INDER LOK",
  },
  {
    schoolId: "2161422",
    schoolName: "JAI RANI BAGH PADAM NAGAR",
  },
  {
    schoolId: "1556043",
    schoolName: "HARI NAGAR GHANTA GHAR",
  },
  {
    schoolId: "1152679",
    schoolName: "DDA FLAT LONI ROAD",
  },
  {
    schoolId: "1958011",
    schoolName: "SOUTH EXTN NEW PART II",
  },
];

export const getFeedbackData = (organisation, feedbackData, school) => {
  const mappings = {
    pratham_demo_1: {
      feedback: {
        instructor_name: feedbackData.feedback.instructor_name,
        instructor_phone_number: feedbackData.feedback.instructor_phone_number,
        gender: feedbackData.feedback.gender,
        age: feedbackData.feedback.age,
        reading_level: feedbackData.feedback.reading_level,
        student_name: feedbackData.feedback.student_name,
      },
    },
    bihar_demo_1: { feedback: { gender: feedbackData.feedback.gender } },
    bihar_pilot_1: { feedback: { gender: feedbackData.feedback.gender } },
    bihar_pilot_2: { feedback: { gender: feedbackData.feedback.gender } },
    delhi_mcd_1: {
      feedback: {
        teacher_name: feedbackData.feedback.instructor_name,
        gender: feedbackData.feedback.gender,
        student_name: feedbackData.feedback.student_name,
        school_name: school?.schoolName,
        grade_section: feedbackData.feedback.grade_section,
        school_shift: feedbackData.feedback.school_shift,
      },
    },
    isaksham_pilot_2: {
      feedback: {
        collector_name: feedbackData.feedback.data_collector_name,
        collector_phone_number:
          feedbackData.feedback.data_collector_phone_number,
        student_name: feedbackData.feedback.student_name,
        gender: feedbackData.feedback.gender,
      },
    },
    bcg_rajasthan_1: {
      feedback: {
        gender: feedbackData.feedback.gender,
        grade_section: feedbackData.feedback.grade_section,
      },
    },
  };
  return mappings[organisation];
};
