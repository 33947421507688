import React, { useEffect } from "react";
import DemoApp from "./apps/DemoApp";
import useParamValues from "./utils/useParamValues";
import useTranslationSetup from "./utils/useTranslationSetup";
import * as Sentry from "@sentry/react";
import { UrlConstants } from "./utils/CommonUtils/constants";

Sentry.init({
  dsn: UrlConstants.DSN_SENTRY_URL,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: process.env.REACT_APP_ENVIRONMENT,
  debug: false,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  const { organisation } = useParamValues();
  const { setLanguage } = useTranslationSetup();

  useEffect(() => {
    const storedLanguage = localStorage.getItem("app_language");
    if (
      organisation === "bihar_demo_1" ||
      organisation === "bihar_pilot_1" ||
      organisation === "bihar_pilot_2" ||
      organisation === "delhi_mcd_1" ||
      organisation === "bcg_rajasthan_1" ||
      organisation === "isaksham_pilot_2" ||
      organisation === "bcg_raj_2"
    ) {
      setLanguage(storedLanguage || "hi");
    } else {
      setLanguage("en");
    }
  }, [organisation, setLanguage]);

  return (
    <>
      {(() => {
        switch (organisation) {
          case "demo":
            return <DemoApp organisation={organisation} />;
          case "bihar_demo_1":
            return <DemoApp organisation={organisation} />;
          default:
            return <DemoApp organisation={organisation} />;
        }
      })()}
    </>
  );
}

export default App;
